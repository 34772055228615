<template>
  <v-container>
    <v-row justify="space-between">
      <v-col cols="12" md="4">
        <v-btn
          small
          dark
          outlined
          :color="$store.state.secondaryColor"
          @click="goBack"
        >
          <v-icon small left>close</v-icon>
          <span class="caption">{{ $t("cancel") }}</span>
        </v-btn>
      </v-col>
      <v-col cols="12" md="4" align-self="end" class="text-right">
        <v-btn
          small
          dark
          :color="$store.state.primaryColor"
          :loading="loading"
          @click="updateSupplierProfile"
        >
          <v-icon small left>save</v-icon>
          <span class="caption">{{ $t("save changes") }}</span>
        </v-btn>
      </v-col>
    </v-row>

    <small>*indicated required field</small>
    <v-divider class="my-2"></v-divider>
    <div v-if="pleaseWait" class="mt-3">
      <PleaseWait></PleaseWait>
    </div>

    <v-layout row wrap class="caption mt-1" v-else>
      <v-flex xs12 sm6 md3 lg3 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("selected business") }} *</span
        >
        <v-text-field
          v-if="selectedBusiness"
          outlined
          dense
          v-model="selectedBusinessName"
          readonly
          :rules="rules.emptyField"
        ></v-text-field>

        <v-select
          v-else
          outlined
          dense
          item-text="businessName"
          item-value="id"
          :items="ownersBusinesses"
          v-model="supplier.businessID"
          :rules="rules.emptyField"
        ></v-select>
      </v-flex>
      <v-flex xs12 sm6 md3 lg3 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("supplier name") }}*</span
        >

        <v-text-field outlined dense v-model="supplier.name"></v-text-field>
      </v-flex>

      <v-flex xs12 sm6 md3 lg3 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("email") }}*</span
        >
        <v-text-field outlined dense v-model="supplier.email"></v-text-field>
      </v-flex>

      <v-flex xs12 sm6 md3 lg3 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("telephone") }}*</span
        >
        <vue-tel-input
          defaultCountry="Ke"
          mode="International"
          v-model="supplier.phone"
          :rules="rules.emptyField"
          style="height: 40px"
        ></vue-tel-input>
      </v-flex>
    </v-layout>

    <v-layout row wrap class="caption mt-1">
      <v-flex xs12 sm6 md3 lg3 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("PO BOX") }}*</span
        >
        <v-text-field
          outlined
          dense
          v-model="supplier.address.pobox"
        ></v-text-field>
      </v-flex>

      <v-flex xs12 sm6 md3 lg3 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("country") }}*</span
        >
        <v-text-field
          outlined
          dense
          v-model="supplier.address.country"
        ></v-text-field>
      </v-flex>

      <v-flex xs12 sm6 md3 lg3 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("county-province") }}*</span
        >
        <v-text-field
          outlined
          dense
          v-model="supplier.address.county"
        ></v-text-field>
      </v-flex>

      <v-flex xs12 sm6 md3 lg3 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("city-town") }}*</span
        >
        <v-text-field
          outlined
          dense
          v-model="supplier.address.city"
        ></v-text-field>
      </v-flex>
    </v-layout>

    <v-layout row wrap class="caption mt-1">
      <v-flex xs12 sm6 md4 lg4 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("district-territory") }}*</span
        >
        <v-text-field
          outlined
          dense
          v-model="supplier.address.territory"
        ></v-text-field>
      </v-flex>

      <v-flex xs12 sm4 md4 lg4 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("street") }}*</span
        >
        <v-text-field
          outlined
          dense
          v-model="supplier.address.street"
        ></v-text-field>
      </v-flex>

      <v-flex xs12 sm4 md4 lg4 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("estate") }}*</span
        >
        <v-text-field
          outlined
          dense
          v-model="supplier.address.estate"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12 sm6 md6 lg6 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("building") }}*</span
        >
        <v-text-field
          outlined
          dense
          v-model="supplier.address.building"
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 md6 lg6 class="px-1">
        <span class="font-weight-medium text-capitalize"
          >{{ $t("house-suite-number") }}*</span
        >
        <v-text-field
          outlined
          dense
          v-model="supplier.address.no"
        ></v-text-field>
      </v-flex>
    </v-layout>

    <v-snackbar
      v-model="snackbar"
      top
      center
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("supplier profile was edited successfully") }}!</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackbar = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackError"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span> {{ $t("could not get supplier details. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackErrorUpdate"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>
        {{ $t("could not update supplier details. Try again later") }}</span
      >
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackErroUsnackErrorUpdatee = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
import PleaseWait from "@/components/templates/PleaseWait";
export default {
  components: {
    PleaseWait,
  },
  data: () => ({
    snackbar: false,
    timeout: 2000,
    pleaseWait: true,
    supplier: "",
    snackErrorUpdate: false,
    snackError: false,
    loading: false,
  }),

  computed: {
    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },
    selectedBusiness() {
      return this.$store.getters.getCurrentBusiness;
    },

    selectedBusinessName() {
      let busId = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      let busName = busId.businessName;
      return busName;
    },
    rules() {
      return {
        emptyField: [(v) => !!v || this.$t("this field is required")],
      };
    },
  },
  mounted() {
    this.getSupplierProfile();
  },

  methods: {
    getSupplierProfile() {
      db.collection("suppliers")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.supplier = doc.data();
          this.pleaseWait = false;
        })
        .catch(() => {
          this.snackError = true;
        });
    },

    updateSupplierProfile() {
      this.loading = true;
      db.collection("suppliers")
        .doc(this.$route.params.id)
        .update(this.supplier)
        .then(() => {
          this.loading = false;
          this.snackbar = true;
          setTimeout(() => this.goBack(), 2000);
        })
        .catch(() => {
          this.snackErrorUpdate = true;
        });
    },

    goBack() {
      this.$router.push({
        name: "ViewSupplierProfile",
        params: { id: this.$route.params.id },
      });
    },
  },
};
</script>

<style></style>
